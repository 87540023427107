import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectLatestCampaignId } from 'selectors/campaigns'
import { selectCurrentOrganization } from 'selectors/organizations'

import NewReportingProtip from 'components/new_reporting_protip'

function NewReportingProtipContainer() {
  const latestCampaignId = useSelector((state) => selectLatestCampaignId(state))
  const organization = useSelector((state) => selectCurrentOrganization(state))
  const { campaignId: routeCampaignId } = useParams()

  return (
    <NewReportingProtip
      campaignId={routeCampaignId || latestCampaignId}
      organization={organization}
    />
  )
}

NewReportingProtipContainer.propTypes = {}
NewReportingProtipContainer.defaultProps = {}

export default NewReportingProtipContainer
