import React from 'react'
import { useBoolFlag } from 'context/launch_darkly'
import { FlagKeys } from 'constants/features'

import { faLightbulb } from '@fortawesome/pro-light-svg-icons'

import ProtipPopup from 'furniture/protip_popup'

function BillingChangesProtip() {
  const shouldShowProtip = useBoolFlag(FlagKeys.SHOW_BILLING_CHANGES_PROTIP)

  if (!shouldShowProtip) return null

  return (
    <div className='content pb-0'>
      <ProtipPopup
        title='Important changes effective September 3, 2024'
        icon={faLightbulb}
        canDismiss={false}
      >
        <p>
          We have unified the billing experience for users in 15Five and
          Engagement to provide better visibility into your billing. This means
          you will only be billed for active users which includes all users that
          have accepted their 15Five invitation or have been manually activated
          by an admin. Please visit{' '}
          <a href='https://s.15five.com/s/kqRcpA'>this help article</a> for more
          information.
        </p>
        <ul>
          <li>
            If you had an Engagement campaign that was already started on
            September 3, this change will not affect you until after your
            campaign closes but will apply to future campaigns
          </li>
          <li>
            If you have an Engagement campaign starting after September 3, 2024,
            it&rsquo;s important to ensure that all users you want to receive a
            survey are all active users in 15Five and{' '}
            <a href='https://s.15five.com/s/dr1kJg'>synced to Engagement</a>.
          </li>
        </ul>
        <p>
          If you have any questions, please reach out to{' '}
          <a href='mailto:{{ mailto }}'>support@15five.com</a>.
        </p>
      </ProtipPopup>
    </div>
  )
}

export default BillingChangesProtip
