const API_CALL = 'API_CALL'

export const CLEAR_ENTITIES = 'CLEAR_ENTITIES'
export const CLEAR_ENTITY_BY_ID = 'CLEAR_ENTITY_BY_ID'
export const CLEAR_RELATIONSHIP = 'relationships/CLEAR_RELATIONSHIP'
export const CLEAR_PERSON_PERMISSIONS = 'CLEAR_PERSON_PERMISSIONS'
export const CLEAR_CAMPAIGN_NOTIFICATIONS =
  'entities/CLEAR_CAMPAIGN_NOTIFICATIONS'
export const ADJUST_EMPLOYEE_MEMBERSHIP_COUNT =
  'entities/ADJUST_EMPLOYEE_MEMBERSHIP_COUNT'

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const statuses = {
  PREREQUEST: 'PREREQUEST',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

export const entities = {
  AI_OPEN_RESPONSE_THEMES: 'ai-open-response-themes',
  AI_OPEN_RESPONSE_SUMMARIES: 'ai-open-response-summaries',
  ACTION_OCCURRENCES: 'action-occurrences',
  ACTION_PLANS: 'action-plans',
  ACTION_PLAN_DRIVER_CHANGES: 'action-plan-driver-changes',
  ACTION_RESOURCES: 'action-resources',
  ACTIONS: 'actions',
  AVERAGE_SCORES: 'average-scores',
  ASSESSMENT_STATEMENT_RESULTS: 'assessment-statement-results',
  BENCHMARKS: 'benchmarks',
  BENCHMARK_TYPES: 'benchmark-types',
  BRACKETS: 'brackets',
  BOOKMARKS: 'bookmarks',
  CAMPAIGN_CATEGORY_SCORES: 'campaign-category-scores',
  CAMPAIGN_CATEGORIES: 'campaign-categories',
  CAMPAIGN_FILTERS: 'campaign-filters',
  CAMPAIGN_MEMBERSHIPS: 'campaign-memberships',
  CAMPAIGN_NOTIFICATIONS: 'campaign-notifications',
  CAMPAIGN_PARTICIPATIONS: 'campaign-participations',
  CAMPAIGN_PARTICIPANTS: 'campaign-participants',
  CAMPAIGN_PROMPTS: 'campaign-prompts',
  CAMPAIGN_RESPONSE_IDS: 'campaign-response-ids',
  CAMPAIGN_RESULTS: 'campaign-results',
  CAMPAIGN_STATEMENTS: 'campaign-statements',
  CAMPAIGN_SURVEYS: 'campaign-surveys',
  CAMPAIGN_TARGETS: 'campaign-targets',
  CAMPAIGNS: 'campaigns',
  CATEGORIES: 'categories',
  CATEGORY_SCORES_CORRELATIONS: 'category-scores-correlations',
  CHANGES: 'changes',
  COACHING_ENTITLEMENTS: 'coaching-entitlements',
  COACHING_SESSIONS: 'coaching-sessions',
  COACHING_SESSION_INSIGHTS: 'coaching-session-insights',
  COACHING_SESSION_PARTICIPANTS: 'coaching-session-participants',
  COACHING_SESSION_TYPES: 'coaching-session-types',
  COACHING_SUBSCRIPTION_PARTICIPANTS: 'coaching-subscription-participants',
  COACHING_SUBSCRIPTIONS: 'coaching-subscriptions',
  COACHING_SUBSCRIPTION_TRANSFORM_RESOURCE_DEADLINES:
    'coaching-subscription-transform-resource-deadlines',
  COACHING_SUBSCRIPTION_STATS: 'coaching-subscription-stats',
  COACHING_SUBSCRIPTION_TYPES: 'coaching-subscription-types',
  COMPILED_MESSAGE_PARTIALS: 'compiled-message-partials',
  COMPILED_MESSAGE_TEMPLATES: 'compiled-message-templates',
  COMPLETED_RESPONDENTS_CSV: 'completed-respondents-csv',
  CROSS_SEGMENTS: 'cross-segments',
  CUSTOM_CAMPAIGNS: 'custom-campaigns',
  CUSTOMER_SUCCESS: 'customer-success',
  EMPLIFY_SCORE_SUMMARIES: 'emplify-score-summaries',
  EMPLOYEE_ATTRIBUTES: 'employee-attributes',
  EMPLOYEES: 'employees',
  EMPLOYEE_CSV: 'employee-csv',
  EMPLOYMENTS: 'employments',
  EMPLOYEE_RESULTS: 'employee-results',
  EMPLOYEE_RESULT_CATEGORY_SCORES: 'employee-result-category-scores',
  EMPLOYEE_MANAGEMENT_ITEMS: 'employee-management-items',
  EMPLOYEE_MANAGEMENT_ITEMS_BULK: 'employee-management-items-bulk',
  ENPS_STATEMENT_RESULTS: 'enps-statement-results',
  ENTITY_TAGS: 'entity-tags',
  EXPLICIT_PERMISSIONS: 'explicit-permissions',
  FACILITATION_TEAMS: 'facilitation-teams',
  FACILITATION_TEAM_MEMBERS: 'facilitation-team-members',
  FEEDBACK_RESPONSES: 'feedback-responses',
  FF_EMPLOYEE_CSV: 'ff-employee-csv',
  FF_GROUP_TYPES: 'ff-group-types',
  GENERATIONS: 'generations',
  GENERATION_GROUPS: 'generation-groups',
  GROUP_BENCHMARKS: 'group-benchmarks',
  GROUP_CATEGORY_SCORES: 'group-category-scores',
  GROUP_EMPLIFY_BRACKETS: 'group-emplify-brackets',
  GROUP_PARTICIPATIONS: 'group-participations',
  GROUP_RESPONSES: 'group-responses',
  GROUP_RESULTS: 'group-results',
  GROUP_TYPES: 'group-types',
  GROUP_TYPES_TO_V3_GROUPS: 'group-types-to-v3-groups',
  GROUPS_TYPES: 'groups-types',
  GROUPS: 'groups',
  HIRE_COHORT_GROUPS: 'hire-cohort-groups',
  ITEMS: 'items',
  INTERNAL_PEOPLE: 'internal-people',
  JOBS: 'jobs',
  JOB_TYPES: 'job-types',
  KIOSK_RESPONDENTS_CSV: 'kiosk-respondents-csv',
  LOCALES: 'locales',
  MEMBERSHIPS: 'memberships',
  MESSAGES_ERRORED_CSV: 'message-error-csv',
  MESSAGES: 'messages',
  NON_RESPONDENTS_CSV: 'non-respondents-csv',
  OPEN_RESPONSES: 'open-responses',
  OPTIMIZED_EMPLOYEES: 'optimized-employees',
  OPTIMIZED_V3_GROUPS: 'optimized-v3-groups',
  OPTIMIZED_ORGANIZATIONS: 'optimized-organizations',
  ORGANIZATION_BENCHMARKS: 'organization-benchmarks',
  ORGANIZATION_EMPLOYEE_ATTRIBUTES: 'organization-employee-attributes',
  ORGANIZATION_FACILITATION_TEAMS: 'organization-facilitation-teams',
  ORGANIZATION_FACILITATORS: 'organization-facilitators',
  ORGANIZATION_FILES_DOWNLOAD: 'organization-files-download',
  ORGANIZATION_FILES_UPLOAD: 'organization-files-upload',
  ORGANIZATION_FILES: 'organization-files',
  ORGANIZATION_GROUP_TYPES: 'organization-group-types',
  ORGANIZATION_LOCALES: 'organization-locales',
  ORGANIZATION_OVERVIEW_PDF: 'organization-overview-pdf',
  ORGANIZATION_PACKAGE_PROGRESS: 'organization-package-progress',
  ORGANIZATION_STATEMENTS: 'organization-statements',
  ORGANIZATION_SUBSTITUTIONS: 'organization-substitutions',
  ORGANIZATIONS: 'organizations',
  PARSED_SURVEY: 'parsed-survey',
  PEOPLE: 'people',
  PEOPLE_EVENT_SCHEDULINGS: 'people-event-schedulings',
  PERMISSIONS: 'permissions',
  PERSON_ROLES: 'person-roles',
  PRODUCT_FEATURES: 'product-features',
  QUERY_CAMPAIGN_THEMES: 'query-campaign-themes',
  QUERY_CAMPAIGN_SUMMARIES: 'query-campaign-summaries',
  PROMPTS: 'prompts',
  RESPONSES: 'responses',
  RESULTS: 'results',
  ROLES: 'roles',
  SEGMENTED_FEEDBACK_RESPONSES_CSV: 'segmented-feedback-responses-csv',
  CUSTOM_STATEMENT_RESPONSE_DATA_CSV: 'custom-statement-response-data-csv',
  SENTIMENT: 'sentiment',
  SMART_PULSE: 'smart-pulse',
  SHORT_URL: 'short-url',
  SURVEY_XML: 'survey-xml',
  SURVEY_CSV: 'survey-csv',
  STATEMENTS: 'statements',
  EXECUTIVE_ADVISORS: 'executive-advisors',
  SUBSTITUTION_TYPES: 'substitution-types',
  SUBSTITUTIONS: 'substitutions',
  SURVEY_PROMPTS: 'survey-prompts',
  SURVEY_STATEMENTS: 'survey-statements',
  SURVEY_TYPES: 'survey-types',
  SURVEYS: 'surveys',
  UNIQUE_EMPLOYEE_ATTRIBUTES: 'unique-employee-attributes',
  TAGS: 'tags',
  TAG_MAPS: 'tag-maps',
  TENURE_GROUPS: 'tenure-groups',
  TEXT_ANALYSIS: 'text-analysis',
  TRANSFORM_USERS: 'transform-users',
  TRANSFORM_PATHS: 'transform-paths',
  TRANSFORM_RESOURCES: 'transform-resources',
  UPLOAD_URL: 'upload-url',
  V2_EMPLOYEE_PREVIEWS: 'v2-employee-previews',
  V2_EMPLOYEE_MIGRATIONS: 'v2-employee-migrations',
  V2_GROUP_TYPE_MIGRATIONS: 'v2-group-type-migrations',
  V3_CAMPAIGN_MEMBERSHIPS: 'v3-campaign-memberships',
  V3_CATEGORY_SCORES: 'v3-category-scores',
  V3_GROUPS: 'v3-groups',
  V3_GROUP_EMPLIFY_BRACKETS: 'v3-group-emplify-brackets',
  V3_GROUP_PARTICIPATIONS: 'v3-group-participations',
  V3_GROUP_RESULTS: 'v3-group-results',
  V3_MEMBERSHIPS: 'v3-memberships',
}

/**
 * @param {string} entity - The entity that the API call is about
 * @param {string} method - HTTP method. GET, POST, PATCH, or DELETE
 * @param {string} status - REQUEST, SUCCESS, or FAILURE
 * @returns {string} the action type that represents API call described by the inputs
 *
 * Example Outputs:
 * "API_CALL/GET/REQUEST/people"
 * "API_CALL/POST/FAILURE/organizations"
 */
export function createApiCallActionType(entity, method, status) {
  return `${API_CALL}/${method}/${status}/${entity}`
}

/**
 * Determine if an action is an API call action.
 *
 * @param {string} type - An action type
 * @returns {boolean} - True if the input action is an API call action. Otherwise, false.
 */
export function isApiCall(type = '') {
  if (!type || typeof type !== 'string') {
    return false
  }

  return type.startsWith(API_CALL)
}
