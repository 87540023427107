import React from 'react'
import PropTypes from 'prop-types'
import { useBoolFlag } from 'context/launch_darkly'
import { FlagKeys } from 'constants/features'
import { useLocation } from 'react-router-dom'
import { get } from 'lodash'

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import ProtipPopup from 'furniture/protip_popup'

const PAGES = {
  GROUP_RESULTS: '/group_results',
  ACTIONS: '/action_dashboard',
  FEEDBACK: '/feedback/engagement',
}

const getTitle = (page) => {
  switch (page) {
    case PAGES.GROUP_RESULTS:
    case PAGES.FEEDBACK:
      return 'This report is outdated.'
    case PAGES.ACTIONS:
      return 'Actions & Action Plans has moved.'
    default:
      return null
  }
}

const getContent = (page, organization, campaignId) => {
  let redirect
  switch (page) {
    case PAGES.GROUP_RESULTS:
      redirect = getResultsLink(organization, campaignId)
      return (
        <p>
          You are viewing legacy reporting in Engage. To view all new reports
          including AI powered insights, and what the Predictive Impact Model
          identified as impacting your teams engagement,{' '}
          <a href={redirect} target='_blank' rel='noopener noreferrer'>
            go here.
          </a>
        </p>
      )
    case PAGES.ACTIONS:
      redirect = getActionPlansLink(organization)
      return (
        <p>
          This Actions feature is being replaced by a new elevated action
          planning feature soon. To create new action plans or explore the new
          feature,{' '}
          <a href={redirect} target='_blank' rel='noopener noreferrer'>
            go here.
          </a>
        </p>
      )
    case PAGES.FEEDBACK:
      redirect = getResultsLink(organization, campaignId)
      return (
        <p>
          You are viewing legacy reporting in Engage. To view all new reports
          including Feedback,{' '}
          <a href={redirect} target='_blank' rel='noopener noreferrer'>
            go here.
          </a>
        </p>
      )
    default:
      return null
  }
}

const getCurrentPage = (pathname) => {
  if (pathname.includes(PAGES.GROUP_RESULTS)) return PAGES.GROUP_RESULTS
  if (pathname.includes(PAGES.ACTIONS)) return PAGES.ACTIONS
  if (pathname.includes(PAGES.FEEDBACK)) return PAGES.FEEDBACK
  return null
}

const getFFDestinationHost = (organization) => {
  const ffHost = process.env.FF_APP_URL
  const ffSubdomain = get(organization, 'ffSubdomain')
  return ffHost.replace('{{ffSubdomain}}', ffSubdomain || 'my')
}

const getResultsLink = (organization, campaignId) => {
  const destinationHost = getFFDestinationHost(organization)
  return destinationHost.replace(
    '{{{path}}}',
    `/engage/campaigns/campaign-summary/?campaignId=${campaignId}`,
  )
}

const getActionPlansLink = (organization) => {
  const destinationHost = getFFDestinationHost(organization)
  return destinationHost.replace('{{{path}}}', '/actions/action-plans/')
}

function NewReportingProtip({ campaignId, organization }) {
  const location = useLocation()
  const shouldShowProtip = useBoolFlag(FlagKeys.FF_INSIGHTS_RESULTS_LINK)
  const page = getCurrentPage(location.pathname)
  const isFFManaged = get(organization, 'isFFManaged')

  if (!shouldShowProtip || !page || !isFFManaged) return null

  const title = getTitle(page)
  const content = getContent(page, organization, campaignId)

  return (
    <div className='content pb-0 m-0'>
      <ProtipPopup
        title={title}
        icon={faExclamationTriangle}
        canDismiss={false}
        containerClassName='new-reporting-protip'
      >
        {content}
      </ProtipPopup>
    </div>
  )
}

NewReportingProtip.propTypes = {
  campaignId: PropTypes.string,
  organization: PropTypes.object,
}

NewReportingProtip.defaultProps = {
  campaignId: null,
  organization: null,
}

export default NewReportingProtip
